import React, { useState } from "react";
import styled from "@emotion/styled";
import { _post } from "../../utils/services/api.service";
import FooterRichText from "./FooterRichText";
import { theme } from "../../utils/global-styles/theme";

const Section = styled.section`
  display: flex;
  padding: 30px;
  color: white;
  font-size: 16px;
  flex-direction: column;

  @media (min-width: 768px) {
    padding: 44px 44px;
    row-gap: 44px;
  }

  @media (min-width: 991px) {
    padding: 51px 80px;
    row-gap: 44px;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
      .breakpoints.desktopUp}) {
    flex-direction: column;
  }
`;

const FooterFormSection = styled.div`
  margin-bottom: 37px;

  @media (min-width: 768px) {
    width: 41%;
  }

  @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
      .breakpoints.desktopUp}) {
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const NewsletterForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  display: flex;
`;

const TAndCs = styled.div`
  font-size: 0.8rem;
  color: white;
  margin-top: 10px;
`;

const FooterLinksSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
      .breakpoints.desktopUp}) {
    width: 100%;
  }
`;

const FooterSection = styled.div`
  @media (min-width: 768px) {
    flex-direction: row;
    margin-right: 20px;
  }

  @media only screen and (min-width: 991px) {
    margin-right: 63px;
  }

  @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
      .breakpoints.desktopUp}) {
    width: 25%;
  }
`;

const SectionTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 38px;
  font-family: ${theme.fontFaces.headers};

  @media only screen and (min-width: 991px) {
    margin-bottom: 35px;
  }
`;

const HorizontalLinks = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: column;
  }
`;

const CTALink = styled.a`
  margin-bottom: 41px;
  width: 50%;
  font-weight: normal;

  @media (min-width: 768px) {
    flex-direction: column;
    width: unset;
  }
`;

const CTAText = styled.div`
  font-size: 16px;
  color: white;
  font-family: ${theme.fontFaces.paragraphs};

  position: relative;
  display: inline-block;
  text-decoration: none;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 23px;
    height: 1px;
    width: 0;
    background-color: white;
    transition: width 0.2s ease-out;
  }

  &::before {
    left: 50%;
    transform: translateX(-100%);
  }

  &::after {
    right: 50%;
    transform: translateX(100%);
  }

  &:hover::before,
  &:hover::after {
    width: 50%;
  }

  @media (min-width: 991px) {
    font-size: 20px;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
  }
`;

const BottomSection = styled.div`
  flex-direction: column;
  display: flex;

  @media (min-width: 768px) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
      .breakpoints.desktopUp}) {
    display: none;
  }
`;

const TabletBottomSection = styled.div`
  display: none;
  @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
      .breakpoints.desktopUp}) {
    display: flex;
    flex-direction: column;
  }
`;

const AcumenLogo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 35px;

  @media (min-width: 768px) {
    width: unset;
    cursor: pointer;
    margin-bottom: unset;
  }
`;

const TabletSocialLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
`;

const TabletBottomLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
`;

const SocialLogos = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;

  @media (min-width: 768px) {
    display: flex;
    width: unset;
    margin-bottom: unset;
  }

  @media (min-width: ${theme.breakpoints.tabletUp}) and (max-width: ${theme
      .breakpoints.desktopUp}) {
    gap: 25px;
  }
`;

const Copyright = styled.div`
  margin-bottom: 35px;
  text-align: center;
  font-size: 16px;

  @media (min-width: 768px) {
    margin-bottom: unset;
  }
`;

const MobileTermsPrivacy = styled.div`
  display: flex;
  color: white;
  justify-content: space-between;

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileLink = styled.a`
  text-decoration: none;
  color: white;
`;

const Link = styled.a`
  display: none;

  @media (min-width: 768px) {
    display: block;
    color: white;
  }
`;

const SingleLogo = styled.a`
  cursor: pointer;

  @media (min-width: 768px) {
    margin-right: 6px;
  }
`;

const SearchInput = styled.input`
  border: none;
  border-bottom: ${props =>
    props.isValidEmail ? "1px solid white" : "1px solid red"};
  outline: none;
  padding: 5px;
  margin-right: 20px;
  width: 80%;
  font-size: 20px;
  font-family: ${theme.fontFaces.inputPlaceholders};

  @media (min-width: 768px) {
    width: 60%;
    font-size: 30px;
  }

  &::placeholder {
    color: ${theme.colors.secondary};
  }
`;

const SubmitButton = styled.button`
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
`;

const Icon = styled.div`
  svg {
    fill: #fff; /* Change the fill color as needed */
  }
`;

const SuccessBanner = styled.div`
  color: white;
  font-size: 26px;
  margin: 10px 0px;
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  color: red;
`;

const Footer = ({
  newsletterHeading,
  newsLetterDescription,
  column1Heading,
  column1Links,
  column2Heading,
  column2Links,
  column3Heading,
  column3Links,
  column4Heading,
  column4Links,
  facebook,
  linkedin,
  twitter,
  youtube,
  termsOfUse,
  privacyPolicy,
  successMessage,
  errorMessage,
  emailErrorMessage,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [newsletterMessage, setNewsletterMessage] = useState("");
  const [status, setStatus] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const footerLinks = {
    footer_links_section: [
      {
        section_title: column1Heading,
        links_group: column1Links,
      },
      {
        section_title: column2Heading,
        links_group: column2Links,
      },
      {
        section_title: column3Heading,
        links_group: column3Links,
      },
      {
        section_title: column4Heading,
        links_group: column4Links,
      },
    ],
  };

  const handleChange = event => {
    setIsValidEmail(true);
    const inputValue = event.target.value;
    setSearchValue(inputValue);
  };

  const handleSubmit = async (event, value) => {
    event.preventDefault();

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(searchValue));

    if (emailRegex.test(searchValue)) {
      const request = {
        email: searchValue,
      };
      const apiResponse = await _post({
        isPublic: true,
        args: request,
        endpoint: "newsletters",
        isPublic: true,
      });
      if (apiResponse.success) {
        setStatus("success");
        setNewsletterMessage(successMessage);
      } else {
        setStatus("failure");
        setNewsletterMessage(errorMessage);
      }
    }
  };

  return (
    <Section className="dark-blue-bg">
      <TopSection>
        <FooterFormSection>
          <Title>{newsletterHeading}</Title>
          <NewsletterForm>
            <Form>
              {status === "success" ? (
                <SuccessBanner>{newsletterMessage} </SuccessBanner>
              ) : (
                <>
                  <SearchInput
                    value={searchValue}
                    onChange={handleChange}
                    type="text"
                    isValidEmail={isValidEmail}
                    className="dark-blue-bg secondary"
                    placeholder="you@email.com"
                  />

                  <SubmitButton
                    aria-label="Submit Newsletter form"
                    role="button"
                    onClick={handleSubmit}
                    className="dark-blue-bg"
                  >
                    <Icon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                      >
                        <path
                          id="Path_982"
                          data-name="Path 982"
                          d="M9.625,6.75H.5V5.25H9.625L5.438,1.063,6.5,0l6,6-6,6L5.438,10.938Z"
                          transform="translate(-0.5)"
                        />
                      </svg>
                    </Icon>
                  </SubmitButton>
                </>
              )}
            </Form>
            {!isValidEmail && <ErrorMessage>{emailErrorMessage}</ErrorMessage>}
            {status === "failure" && (
              <ErrorMessage>{newsletterMessage}</ErrorMessage>
            )}
          </NewsletterForm>
          <TAndCs>
            <FooterRichText richText={newsLetterDescription} />
          </TAndCs>
        </FooterFormSection>
        <FooterLinksSection>
          {footerLinks?.footer_links_section.map((section, index) => (
            <FooterSection key={index}>
              <SectionTitle>{section.section_title}</SectionTitle>
              <HorizontalLinks>
                {section.links_group.map((nav, navIndex) => (
                  <CTALink href={nav.url} key={navIndex}>
                    <CTAText>{nav.linkText}</CTAText>
                  </CTALink>
                ))}
              </HorizontalLinks>
            </FooterSection>
          ))}
        </FooterLinksSection>
      </TopSection>
      <BottomSection>
        <AcumenLogo>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="175.998"
            height="53.64"
            viewBox="0 0 175.998 53.64"
          >
            <g
              id="Group_102"
              data-name="Group 102"
              transform="translate(24418 -3781)"
            >
              <g
                id="Group_47"
                data-name="Group 47"
                transform="translate(-24498 3184.641)"
              >
                <path
                  id="Path_863"
                  data-name="Path 863"
                  d="M66.248,21.4l-2.011-5.3-2.059,5.3Zm1.16,3.005h-6.39l-1.064,2.769H56.238l6.578-16.566h2.84l6.6,16.566h-3.81Z"
                  transform="translate(97.471 599.656)"
                  fill="#fff"
                />
                <path
                  id="Path_864"
                  data-name="Path 864"
                  d="M68.59,20.264a5.919,5.919,0,0,1,6.034-6.058A5.532,5.532,0,0,1,80.3,18.868H76.919A2.291,2.291,0,0,0,74.624,17.4a2.736,2.736,0,0,0-2.768,2.863,2.744,2.744,0,0,0,2.768,2.84,2.333,2.333,0,0,0,2.319-1.562h3.384a5.488,5.488,0,0,1-5.7,4.756,5.9,5.9,0,0,1-6.034-6.034"
                  transform="translate(101.309 600.772)"
                  fill="#fff"
                />
                <path
                  id="Path_865"
                  data-name="Path 865"
                  d="M90.237,14.387V26.006H86.9V24.467a3.581,3.581,0,0,1-3.147,1.775c-2.982,0-4.78-2.011-4.78-5.3V14.387H82.31v6.129a2.306,2.306,0,1,0,4.59,0V14.387Z"
                  transform="translate(104.533 600.829)"
                  fill="#fff"
                />
                <path
                  id="Path_866"
                  data-name="Path 866"
                  d="M107.553,18.844v7.218h-3.361V19.626a1.972,1.972,0,0,0-1.987-2.224,2.043,2.043,0,0,0-2.106,2.224v6.437H96.763V19.626A1.976,1.976,0,0,0,94.751,17.4a2.008,2.008,0,0,0-2.058,2.224v6.437H89.356V14.443h3.337v1.4a3.907,3.907,0,0,1,3.313-1.633,3.44,3.44,0,0,1,3.288,1.7,4.315,4.315,0,0,1,3.5-1.7,4.465,4.465,0,0,1,4.756,4.638"
                  transform="translate(107.76 600.772)"
                  fill="#fff"
                />
                <path
                  id="Path_867"
                  data-name="Path 867"
                  d="M112.933,18.915a2.294,2.294,0,0,0-2.391-2.012,2.6,2.6,0,0,0-2.674,2.012Zm3.408,2.13H107.7a2.851,2.851,0,0,0,2.959,2.485c1.3,0,2.13-.569,2.319-1.372h3.266c-.379,2.509-2.579,4.14-5.49,4.14a6.031,6.031,0,0,1-6.106-6.1,5.932,5.932,0,0,1,6.036-5.988,5.656,5.656,0,0,1,5.726,5.656,11.634,11.634,0,0,1-.071,1.184"
                  transform="translate(112.512 600.772)"
                  fill="#fff"
                />
                <path
                  id="Path_868"
                  data-name="Path 868"
                  d="M126.315,19.483v6.58h-3.336V19.909a2.3,2.3,0,1,0-4.591,0v6.154h-3.337V14.443h3.337v1.514a3.672,3.672,0,0,1,3.147-1.751c2.983,0,4.78,1.988,4.78,5.277"
                  transform="translate(115.742 600.772)"
                  fill="#fff"
                />
                <path
                  id="Path_869"
                  data-name="Path 869"
                  d="M66.248,36.135l-2.011-5.3-2.059,5.3Zm1.16,3.005h-6.39L59.954,41.91H56.238l6.578-16.566h2.84l6.6,16.566h-3.81Z"
                  transform="translate(97.471 604.233)"
                  fill="#fff"
                />
                <path
                  id="Path_870"
                  data-name="Path 870"
                  d="M68.59,34.995a5.919,5.919,0,0,1,6.034-6.058A5.532,5.532,0,0,1,80.3,33.6H76.919a2.291,2.291,0,0,0-2.295-1.467,2.853,2.853,0,0,0,0,5.7,2.333,2.333,0,0,0,2.319-1.562h3.384a5.488,5.488,0,0,1-5.7,4.756,5.9,5.9,0,0,1-6.034-6.034"
                  transform="translate(101.309 605.35)"
                  fill="#fff"
                />
                <path
                  id="Path_871"
                  data-name="Path 871"
                  d="M87.5,34.971a2.911,2.911,0,1,0-2.911,2.864A2.854,2.854,0,0,0,87.5,34.971m3.337-5.8V40.793H87.5V39.137a4.22,4.22,0,0,1-3.692,1.893c-3.005,0-5.395-2.721-5.395-6.058s2.389-6.034,5.395-6.034A4.22,4.22,0,0,1,87.5,30.83V29.174Z"
                  transform="translate(104.359 605.35)"
                  fill="#fff"
                />
                <path
                  id="Path_872"
                  data-name="Path 872"
                  d="M98.389,36.086a2.854,2.854,0,0,0-2.911-2.863,2.864,2.864,0,1,0,0,5.728,2.84,2.84,0,0,0,2.911-2.865m3.337-10.742V41.908H98.389V40.252A4.221,4.221,0,0,1,94.7,42.145c-3.005,0-5.395-2.721-5.395-6.059s2.389-6.033,5.395-6.033a4.22,4.22,0,0,1,3.692,1.893v-6.6Z"
                  transform="translate(107.742 604.233)"
                  fill="#fff"
                />
                <path
                  id="Path_873"
                  data-name="Path 873"
                  d="M108.382,33.646a2.294,2.294,0,0,0-2.391-2.012,2.6,2.6,0,0,0-2.674,2.012Zm3.408,2.13h-8.637a2.85,2.85,0,0,0,2.958,2.485c1.3,0,2.13-.569,2.319-1.372H111.7c-.379,2.509-2.579,4.14-5.49,4.14a6.03,6.03,0,0,1-6.105-6.105,5.929,5.929,0,0,1,6.034-5.987,5.656,5.656,0,0,1,5.726,5.656c0,.261-.025.8-.071,1.184"
                  transform="translate(111.098 605.35)"
                  fill="#fff"
                />
                <path
                  id="Path_874"
                  data-name="Path 874"
                  d="M128.7,33.575v7.218h-3.361V34.357a1.972,1.972,0,0,0-1.988-2.224,2.043,2.043,0,0,0-2.1,2.224v6.437h-3.337V34.357a1.977,1.977,0,0,0-2.012-2.224,2.008,2.008,0,0,0-2.059,2.224v6.437H110.5V29.174h3.336v1.4a3.908,3.908,0,0,1,3.313-1.633,3.442,3.442,0,0,1,3.29,1.7,4.313,4.313,0,0,1,3.5-1.7,4.467,4.467,0,0,1,4.758,4.638"
                  transform="translate(114.33 605.35)"
                  fill="#fff"
                />
                <path
                  id="Path_875"
                  data-name="Path 875"
                  d="M137.21,29.118l-6.46,15.476h-3.573l2.153-4.8-4.472-10.673h3.6l2.579,6.792,2.578-6.792Z"
                  transform="translate(118.789 605.405)"
                  fill="#fff"
                />
              </g>
              <path
                id="Path_876"
                data-name="Path 876"
                d="M54.053,50.443H47.864L29,.021H54.053Z"
                transform="translate(-24408.992 3781.007)"
                fill="rgba(255,255,255,0.5)"
              />
              <path
                id="Path_878"
                data-name="Path 878"
                d="M12.589.022H0V41.683H5.026L17.347,8.754H12.589Z"
                transform="translate(-24418 3781.007)"
                fill="rgba(255,255,255,0.5)"
              />
              <path
                id="Path_879"
                data-name="Path 879"
                d="M19.116,8.761V0H12.59V8.761h4.756L5.025,41.69H0v8.759H6.76V41.69h5.775L24.857,8.761Z"
                transform="translate(-24418 3781)"
                fill="#fff"
              />
              <path
                id="Path_880"
                data-name="Path 880"
                d="M23.278,35.186h16.4L36.4,26.426H26.556l4.9-13.091L26.532,0H17.513V8.761h5.741L10.933,41.69H5.158V50.45H26.532V41.69H20.844Z"
                transform="translate(-24416.398 3781)"
                fill="rgba(255,255,255,0.5)"
              />
            </g>
          </svg>
        </AcumenLogo>
        <SocialLogos>
          <SingleLogo href={facebook?.url} aria-label="Facebook-link">
            <svg
              width="32"
              height="32"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.625 7.62502C14.0392 7.62502 14.375 7.28923 14.375 6.87502C14.375 6.4608 14.0392 6.12502 13.625 6.12502V7.62502ZM12.375 6.87502L12.3719 7.62502H12.375V6.87502ZM10.5 8.75002L11.25 8.75002L11.25 8.74687L10.5 8.75002ZM8 10.5C7.58579 10.5 7.25 10.8358 7.25 11.25C7.25 11.6642 7.58579 12 8 12V10.5ZM13 12C13.4142 12 13.75 11.6642 13.75 11.25C13.75 10.8358 13.4142 10.5 13 10.5V12ZM17.25 10C17.25 13.7279 14.2279 16.75 10.5 16.75V18.25C15.0563 18.25 18.75 14.5563 18.75 10H17.25ZM10.5 16.75C6.77208 16.75 3.75 13.7279 3.75 10H2.25C2.25 14.5563 5.94365 18.25 10.5 18.25V16.75ZM3.75 10C3.75 6.27208 6.77208 3.25 10.5 3.25V1.75C5.94365 1.75 2.25 5.44365 2.25 10H3.75ZM10.5 3.25C14.2279 3.25 17.25 6.27208 17.25 10H18.75C18.75 5.44365 15.0563 1.75 10.5 1.75V3.25ZM13.625 6.12502H12.375V7.62502H13.625V6.12502ZM12.3782 6.12502C12.0326 6.12357 11.6902 6.19056 11.3707 6.32213L11.9418 7.70915C12.0782 7.65299 12.2244 7.62439 12.3719 7.62501L12.3782 6.12502ZM11.3707 6.32213C11.0512 6.45369 10.7609 6.64722 10.5166 6.89156L11.5772 7.95222C11.6815 7.84792 11.8054 7.76531 11.9418 7.70915L11.3707 6.32213ZM10.5166 6.89156C10.2722 7.13589 10.0787 7.42619 9.94713 7.7457L11.3341 8.31682C11.3903 8.18043 11.4729 8.05652 11.5772 7.95222L10.5166 6.89156ZM9.94713 7.7457C9.81556 8.06522 9.74857 8.40762 9.75002 8.75316L11.25 8.74687C11.2494 8.59937 11.278 8.45321 11.3341 8.31682L9.94713 7.7457ZM9.75002 8.75002L9.75 17.5L11.25 17.5L11.25 8.75002L9.75002 8.75002ZM8 12H13V10.5H8V12Z"
                fill="white"
              />
            </svg>
          </SingleLogo>

          <SingleLogo href={linkedin?.url} aria-label="LinkedIn-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25.696"
              height="25.589"
              viewBox="0 0 25.696 25.589"
            >
              <g
                id="Group_199"
                data-name="Group 199"
                transform="translate(1690.75 5286.75)"
              >
                <path
                  id="linkedin"
                  d="M4.274,44.816h-2.3V37.459H4.278v7.357ZM3.126,36.454a1.326,1.326,0,1,1,1.331-1.326,1.328,1.328,0,0,1-1.331,1.326Zm9.751,8.362h-2.3V41.237c0-.853-.017-1.951-1.192-1.951s-1.379.928-1.379,1.889v3.641h-2.3V37.459h2.2v1.005h.03a2.42,2.42,0,0,1,2.175-1.186c2.323,0,2.755,1.525,2.755,3.506Z"
                  transform="translate(-1685.384 -5313.42)"
                  fill="#fff"
                />
                <g
                  id="Group_198"
                  data-name="Group 198"
                  transform="translate(-1690 -5286)"
                >
                  <path
                    id="Path_1123"
                    data-name="Path 1123"
                    d="M19.2.813H7.625A6.3,6.3,0,0,0,1.313,7.1V18.618A6.3,6.3,0,0,0,7.625,24.9H19.2a6.3,6.3,0,0,0,6.312-6.284V7.1A6.3,6.3,0,0,0,19.2.813Z"
                    transform="translate(-1.313 -0.813)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="square"
                    stroke-width="1.5"
                  />
                </g>
              </g>
            </svg>
          </SingleLogo>

          <SingleLogo href={twitter?.url} aria-label="Twitter-link">
            <svg
              width="32"
              height="32"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.125 4.375L4.875 15.625M16.125 15.625L4.875 4.375"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
          </SingleLogo>

          <SingleLogo href={youtube?.url} aria-label="YouTube-link">
            <svg
              width="32"
              height="32"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 10L9.25 7.5V12.5L13 10Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.375 9.99992C2.375 12.328 2.61719 13.6874 2.79687 14.3905C2.84642 14.5813 2.94011 14.7578 3.07039 14.9057C3.20067 15.0536 3.36388 15.1689 3.54688 15.2421C6.16406 16.2421 10.5 16.2187 10.5 16.2187C10.5 16.2187 14.8359 16.2421 17.4531 15.2421C17.6361 15.1689 17.7993 15.0536 17.9296 14.9057C18.0599 14.7578 18.1536 14.5813 18.2031 14.3905C18.3828 13.6874 18.625 12.328 18.625 9.99992C18.625 7.6718 18.3828 6.31242 18.2031 5.6093C18.1536 5.41852 18.0599 5.24205 17.9296 5.09414C17.7993 4.94622 17.6361 4.83099 17.4531 4.75774C14.8359 3.75774 10.5 3.78117 10.5 3.78117C10.5 3.78117 6.16406 3.75774 3.54688 4.75774C3.36388 4.83099 3.20067 4.94622 3.07039 5.09414C2.94011 5.24205 2.84642 5.41852 2.79687 5.6093C2.61719 6.31242 2.375 7.6718 2.375 9.99992Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </SingleLogo>
        </SocialLogos>
        <Copyright>{`© ${new Date()
          ?.getFullYear()
          ?.toString()} Acumen Academy`}</Copyright>
        <MobileTermsPrivacy>
          <MobileLink href={termsOfUse?.url}>{termsOfUse.linkText}</MobileLink>
          <MobileLink href={privacyPolicy?.url}>
            {privacyPolicy.linkText}
          </MobileLink>
        </MobileTermsPrivacy>
        <Link href={termsOfUse?.url}>{termsOfUse?.linkText}</Link>
        <Link href={privacyPolicy?.url}>{privacyPolicy?.linkText}</Link>
      </BottomSection>
      <TabletBottomSection>
        <AcumenLogo>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="175.998"
            height="53.64"
            viewBox="0 0 175.998 53.64"
          >
            <g
              id="Group_102"
              data-name="Group 102"
              transform="translate(24418 -3781)"
            >
              <g
                id="Group_47"
                data-name="Group 47"
                transform="translate(-24498 3184.641)"
              >
                <path
                  id="Path_863"
                  data-name="Path 863"
                  d="M66.248,21.4l-2.011-5.3-2.059,5.3Zm1.16,3.005h-6.39l-1.064,2.769H56.238l6.578-16.566h2.84l6.6,16.566h-3.81Z"
                  transform="translate(97.471 599.656)"
                  fill="#fff"
                />
                <path
                  id="Path_864"
                  data-name="Path 864"
                  d="M68.59,20.264a5.919,5.919,0,0,1,6.034-6.058A5.532,5.532,0,0,1,80.3,18.868H76.919A2.291,2.291,0,0,0,74.624,17.4a2.736,2.736,0,0,0-2.768,2.863,2.744,2.744,0,0,0,2.768,2.84,2.333,2.333,0,0,0,2.319-1.562h3.384a5.488,5.488,0,0,1-5.7,4.756,5.9,5.9,0,0,1-6.034-6.034"
                  transform="translate(101.309 600.772)"
                  fill="#fff"
                />
                <path
                  id="Path_865"
                  data-name="Path 865"
                  d="M90.237,14.387V26.006H86.9V24.467a3.581,3.581,0,0,1-3.147,1.775c-2.982,0-4.78-2.011-4.78-5.3V14.387H82.31v6.129a2.306,2.306,0,1,0,4.59,0V14.387Z"
                  transform="translate(104.533 600.829)"
                  fill="#fff"
                />
                <path
                  id="Path_866"
                  data-name="Path 866"
                  d="M107.553,18.844v7.218h-3.361V19.626a1.972,1.972,0,0,0-1.987-2.224,2.043,2.043,0,0,0-2.106,2.224v6.437H96.763V19.626A1.976,1.976,0,0,0,94.751,17.4a2.008,2.008,0,0,0-2.058,2.224v6.437H89.356V14.443h3.337v1.4a3.907,3.907,0,0,1,3.313-1.633,3.44,3.44,0,0,1,3.288,1.7,4.315,4.315,0,0,1,3.5-1.7,4.465,4.465,0,0,1,4.756,4.638"
                  transform="translate(107.76 600.772)"
                  fill="#fff"
                />
                <path
                  id="Path_867"
                  data-name="Path 867"
                  d="M112.933,18.915a2.294,2.294,0,0,0-2.391-2.012,2.6,2.6,0,0,0-2.674,2.012Zm3.408,2.13H107.7a2.851,2.851,0,0,0,2.959,2.485c1.3,0,2.13-.569,2.319-1.372h3.266c-.379,2.509-2.579,4.14-5.49,4.14a6.031,6.031,0,0,1-6.106-6.1,5.932,5.932,0,0,1,6.036-5.988,5.656,5.656,0,0,1,5.726,5.656,11.634,11.634,0,0,1-.071,1.184"
                  transform="translate(112.512 600.772)"
                  fill="#fff"
                />
                <path
                  id="Path_868"
                  data-name="Path 868"
                  d="M126.315,19.483v6.58h-3.336V19.909a2.3,2.3,0,1,0-4.591,0v6.154h-3.337V14.443h3.337v1.514a3.672,3.672,0,0,1,3.147-1.751c2.983,0,4.78,1.988,4.78,5.277"
                  transform="translate(115.742 600.772)"
                  fill="#fff"
                />
                <path
                  id="Path_869"
                  data-name="Path 869"
                  d="M66.248,36.135l-2.011-5.3-2.059,5.3Zm1.16,3.005h-6.39L59.954,41.91H56.238l6.578-16.566h2.84l6.6,16.566h-3.81Z"
                  transform="translate(97.471 604.233)"
                  fill="#fff"
                />
                <path
                  id="Path_870"
                  data-name="Path 870"
                  d="M68.59,34.995a5.919,5.919,0,0,1,6.034-6.058A5.532,5.532,0,0,1,80.3,33.6H76.919a2.291,2.291,0,0,0-2.295-1.467,2.853,2.853,0,0,0,0,5.7,2.333,2.333,0,0,0,2.319-1.562h3.384a5.488,5.488,0,0,1-5.7,4.756,5.9,5.9,0,0,1-6.034-6.034"
                  transform="translate(101.309 605.35)"
                  fill="#fff"
                />
                <path
                  id="Path_871"
                  data-name="Path 871"
                  d="M87.5,34.971a2.911,2.911,0,1,0-2.911,2.864A2.854,2.854,0,0,0,87.5,34.971m3.337-5.8V40.793H87.5V39.137a4.22,4.22,0,0,1-3.692,1.893c-3.005,0-5.395-2.721-5.395-6.058s2.389-6.034,5.395-6.034A4.22,4.22,0,0,1,87.5,30.83V29.174Z"
                  transform="translate(104.359 605.35)"
                  fill="#fff"
                />
                <path
                  id="Path_872"
                  data-name="Path 872"
                  d="M98.389,36.086a2.854,2.854,0,0,0-2.911-2.863,2.864,2.864,0,1,0,0,5.728,2.84,2.84,0,0,0,2.911-2.865m3.337-10.742V41.908H98.389V40.252A4.221,4.221,0,0,1,94.7,42.145c-3.005,0-5.395-2.721-5.395-6.059s2.389-6.033,5.395-6.033a4.22,4.22,0,0,1,3.692,1.893v-6.6Z"
                  transform="translate(107.742 604.233)"
                  fill="#fff"
                />
                <path
                  id="Path_873"
                  data-name="Path 873"
                  d="M108.382,33.646a2.294,2.294,0,0,0-2.391-2.012,2.6,2.6,0,0,0-2.674,2.012Zm3.408,2.13h-8.637a2.85,2.85,0,0,0,2.958,2.485c1.3,0,2.13-.569,2.319-1.372H111.7c-.379,2.509-2.579,4.14-5.49,4.14a6.03,6.03,0,0,1-6.105-6.105,5.929,5.929,0,0,1,6.034-5.987,5.656,5.656,0,0,1,5.726,5.656c0,.261-.025.8-.071,1.184"
                  transform="translate(111.098 605.35)"
                  fill="#fff"
                />
                <path
                  id="Path_874"
                  data-name="Path 874"
                  d="M128.7,33.575v7.218h-3.361V34.357a1.972,1.972,0,0,0-1.988-2.224,2.043,2.043,0,0,0-2.1,2.224v6.437h-3.337V34.357a1.977,1.977,0,0,0-2.012-2.224,2.008,2.008,0,0,0-2.059,2.224v6.437H110.5V29.174h3.336v1.4a3.908,3.908,0,0,1,3.313-1.633,3.442,3.442,0,0,1,3.29,1.7,4.313,4.313,0,0,1,3.5-1.7,4.467,4.467,0,0,1,4.758,4.638"
                  transform="translate(114.33 605.35)"
                  fill="#fff"
                />
                <path
                  id="Path_875"
                  data-name="Path 875"
                  d="M137.21,29.118l-6.46,15.476h-3.573l2.153-4.8-4.472-10.673h3.6l2.579,6.792,2.578-6.792Z"
                  transform="translate(118.789 605.405)"
                  fill="#fff"
                />
              </g>
              <path
                id="Path_876"
                data-name="Path 876"
                d="M54.053,50.443H47.864L29,.021H54.053Z"
                transform="translate(-24408.992 3781.007)"
                fill="rgba(255,255,255,0.5)"
              />
              <path
                id="Path_878"
                data-name="Path 878"
                d="M12.589.022H0V41.683H5.026L17.347,8.754H12.589Z"
                transform="translate(-24418 3781.007)"
                fill="rgba(255,255,255,0.5)"
              />
              <path
                id="Path_879"
                data-name="Path 879"
                d="M19.116,8.761V0H12.59V8.761h4.756L5.025,41.69H0v8.759H6.76V41.69h5.775L24.857,8.761Z"
                transform="translate(-24418 3781)"
                fill="#fff"
              />
              <path
                id="Path_880"
                data-name="Path 880"
                d="M23.278,35.186h16.4L36.4,26.426H26.556l4.9-13.091L26.532,0H17.513V8.761h5.741L10.933,41.69H5.158V50.45H26.532V41.69H20.844Z"
                transform="translate(-24416.398 3781)"
                fill="rgba(255,255,255,0.5)"
              />
            </g>
          </svg>
        </AcumenLogo>
        <TabletSocialLogoWrapper>
          <SocialLogos>
            <SingleLogo href={facebook?.url}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.625 7.62502C14.0392 7.62502 14.375 7.28923 14.375 6.87502C14.375 6.4608 14.0392 6.12502 13.625 6.12502V7.62502ZM12.375 6.87502L12.3719 7.62502H12.375V6.87502ZM10.5 8.75002L11.25 8.75002L11.25 8.74687L10.5 8.75002ZM8 10.5C7.58579 10.5 7.25 10.8358 7.25 11.25C7.25 11.6642 7.58579 12 8 12V10.5ZM13 12C13.4142 12 13.75 11.6642 13.75 11.25C13.75 10.8358 13.4142 10.5 13 10.5V12ZM17.25 10C17.25 13.7279 14.2279 16.75 10.5 16.75V18.25C15.0563 18.25 18.75 14.5563 18.75 10H17.25ZM10.5 16.75C6.77208 16.75 3.75 13.7279 3.75 10H2.25C2.25 14.5563 5.94365 18.25 10.5 18.25V16.75ZM3.75 10C3.75 6.27208 6.77208 3.25 10.5 3.25V1.75C5.94365 1.75 2.25 5.44365 2.25 10H3.75ZM10.5 3.25C14.2279 3.25 17.25 6.27208 17.25 10H18.75C18.75 5.44365 15.0563 1.75 10.5 1.75V3.25ZM13.625 6.12502H12.375V7.62502H13.625V6.12502ZM12.3782 6.12502C12.0326 6.12357 11.6902 6.19056 11.3707 6.32213L11.9418 7.70915C12.0782 7.65299 12.2244 7.62439 12.3719 7.62501L12.3782 6.12502ZM11.3707 6.32213C11.0512 6.45369 10.7609 6.64722 10.5166 6.89156L11.5772 7.95222C11.6815 7.84792 11.8054 7.76531 11.9418 7.70915L11.3707 6.32213ZM10.5166 6.89156C10.2722 7.13589 10.0787 7.42619 9.94713 7.7457L11.3341 8.31682C11.3903 8.18043 11.4729 8.05652 11.5772 7.95222L10.5166 6.89156ZM9.94713 7.7457C9.81556 8.06522 9.74857 8.40762 9.75002 8.75316L11.25 8.74687C11.2494 8.59937 11.278 8.45321 11.3341 8.31682L9.94713 7.7457ZM9.75002 8.75002L9.75 17.5L11.25 17.5L11.25 8.75002L9.75002 8.75002ZM8 12H13V10.5H8V12Z"
                  fill="white"
                />
              </svg>
            </SingleLogo>

            <SingleLogo href={linkedin?.url}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.696"
                height="25.589"
                viewBox="0 0 25.696 25.589"
              >
                <g
                  id="Group_199"
                  data-name="Group 199"
                  transform="translate(1690.75 5286.75)"
                >
                  <path
                    id="linkedin"
                    d="M4.274,44.816h-2.3V37.459H4.278v7.357ZM3.126,36.454a1.326,1.326,0,1,1,1.331-1.326,1.328,1.328,0,0,1-1.331,1.326Zm9.751,8.362h-2.3V41.237c0-.853-.017-1.951-1.192-1.951s-1.379.928-1.379,1.889v3.641h-2.3V37.459h2.2v1.005h.03a2.42,2.42,0,0,1,2.175-1.186c2.323,0,2.755,1.525,2.755,3.506Z"
                    transform="translate(-1685.384 -5313.42)"
                    fill="#fff"
                  />
                  <g
                    id="Group_198"
                    data-name="Group 198"
                    transform="translate(-1690 -5286)"
                  >
                    <path
                      id="Path_1123"
                      data-name="Path 1123"
                      d="M19.2.813H7.625A6.3,6.3,0,0,0,1.313,7.1V18.618A6.3,6.3,0,0,0,7.625,24.9H19.2a6.3,6.3,0,0,0,6.312-6.284V7.1A6.3,6.3,0,0,0,19.2.813Z"
                      transform="translate(-1.313 -0.813)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="square"
                      stroke-width="1.5"
                    />
                  </g>
                </g>
              </svg>
            </SingleLogo>

            <SingleLogo href={twitter?.url}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.125 4.375L4.875 15.625M16.125 15.625L4.875 4.375"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </SingleLogo>

            <SingleLogo href={youtube?.url}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 10L9.25 7.5V12.5L13 10Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.375 9.99992C2.375 12.328 2.61719 13.6874 2.79687 14.3905C2.84642 14.5813 2.94011 14.7578 3.07039 14.9057C3.20067 15.0536 3.36388 15.1689 3.54688 15.2421C6.16406 16.2421 10.5 16.2187 10.5 16.2187C10.5 16.2187 14.8359 16.2421 17.4531 15.2421C17.6361 15.1689 17.7993 15.0536 17.9296 14.9057C18.0599 14.7578 18.1536 14.5813 18.2031 14.3905C18.3828 13.6874 18.625 12.328 18.625 9.99992C18.625 7.6718 18.3828 6.31242 18.2031 5.6093C18.1536 5.41852 18.0599 5.24205 17.9296 5.09414C17.7993 4.94622 17.6361 4.83099 17.4531 4.75774C14.8359 3.75774 10.5 3.78117 10.5 3.78117C10.5 3.78117 6.16406 3.75774 3.54688 4.75774C3.36388 4.83099 3.20067 4.94622 3.07039 5.09414C2.94011 5.24205 2.84642 5.41852 2.79687 5.6093C2.61719 6.31242 2.375 7.6718 2.375 9.99992Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </SingleLogo>
          </SocialLogos>
        </TabletSocialLogoWrapper>
        <TabletBottomLinksWrapper>
          <Copyright>{`© ${new Date()
            ?.getFullYear()
            ?.toString()} Acumen Academy`}</Copyright>
          <Link href={termsOfUse?.url}>{termsOfUse?.linkText}</Link>
          <Link href={privacyPolicy?.url}>{privacyPolicy?.linkText}</Link>
        </TabletBottomLinksWrapper>
      </TabletBottomSection>
    </Section>
  );
};

export default Footer;
